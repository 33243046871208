<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <div class="row">
      <div class="col-md-6">
        <h2 class="titulo-links-escolha">Servidores</h2>
      </div>
      <div class="col-md-6 tbl-cadastro" v-if="situacaoAno && acesso.cadastrar">
        <pm-Button
          type="button"
          icon="pi pi-external-link"
          title="CADASTRAR SERVIDOR"
          @click="registar()"
          label="CADASTRAR SERVIDOR"
          class="p-button-success"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="professorALL"
        id="servidores"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
      >
        <template #header>
          <div class="p-fluid formgrid grid" style="font-size: 12px">
            <div
              class="field col-12 md:col-4 lg:col-3"
              style="margin: 0px"
              v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
            >
              <label style="font-weight: 400 !important"
              >Escolha uma Função:</label
              >
              <select
                class="p-inputtext p-component"
                v-model="servidores"
                @change="listarTudo(1)"
                style="appearance: revert !important; width: 100% !important; height: 34px !important;"

              >
                <option value="0">Todos com funcões</option>
                <option value="100">Sem funções</option>
                <option value="1">Professor</option>
                <option value="2">Coordenador</option>
                <option value="3">Diretor</option>
                <option value="4">Gestor</option>
              </select>
            </div>
            <div v-else>
              <label style="font-weight: 400 !important"
              >Escolha uma Função:</label
              >
              <select
                class="p-inputtext p-component"
                v-model="servidores"
                @change="listarTudo(1)"
                style="appearance: revert !important; width: 100% !important; height: 34px !important;"
              >
                <!-- <option value="100">Sem funções</option> -->
                <option value="1">Professor</option>
                <option value="2">Coordenador</option>
                <option value="3">Diretor</option>
              </select>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <label style="font-weight: 400 !important"
              >Nome do Servidor:</label
              >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <pm-InputText
                  v-model="pesquisa"
                  @input="pesquisar"
                  placeholder="Pesquisar Nome do Servidor"
                  style="height: 34px !important;"
                />
              </span>
            </div>
          </div>
        </template>

        <pm-Column
          v-for="col of items"
          sortable
          :field="col.key"
          :header="col.label"
          :key="col.key"
          class="colunn-tbl"
        >
          <template #body="{ data }">
            <div v-if="col.key == 'foto'">
              <div class="tb-center" v-if="data.id.foto">
                <img
                  v-if="data.id.foto"
                  class="img-perfil-dtb-pq"
                  :src="base_url + '/storage/' + data.id.foto"
                />
              </div>
              <div class="tb-center" v-if="data.id.foto == null">
                <pm-Avatar
                  icon="pi pi-user"
                  class="mr-2"
                  style="background-color: #e1e1e1; color: #ffffff"
                  shape="circle"
                />
                <!--<img v-if="data.id.sexo === 'F'" class="img-perfil-dtb-pq" :src="base_url+'/storage/default/feminino.png'" />
                  <img v-if="data.id.sexo === 'M' " class="img-perfil-dtb-pq" :src="base_url+'/storage/default/masculino.png'" />-->
              </div>
            </div>
            <div v-if="col.key == 'nome'">
              {{ data.nome + " " }}
              <b v-if="data.sobrenome" style="font-weight: 400">{{
                data.sobrenome
              }}</b>
              &nbsp;
              <span
                class="badge bg-danger table_style"
                v-if="data.deficiencia == 1"
                :title="data.qual_deficiencia"
              >NEE</span
              >

              <!--<i class="fa fa-universal-access" v-if="data.deficiencia == 1" style="font-size: 22px;color: #d32f2f;"
                  :title="data.qual_deficiencia" ></i>-->
            </div>
            <div v-if="col.key == 'funcao'">
              {{ data.funcao }}
            </div>
            <div v-if="col.key == 'opcoes'">
              <div v-if="data.opcoes.funcao_id == 5">
                <pm-InputSwitch
                  :disabled="situacaoAno == 0"
                  class="small-switch"
                  v-model="status[data.opcoes.index]"
                  @click="mudarStatus(data.opcoes)"
                />
              </div>
            </div>

            <div v-if="col.key == 'escola_nome'">
              <div v-for="n in data.escola_nome" :key="n">
                <b>{{ n.nome }} </b>
              </div>
            </div>
            <div v-if="col.key == 'escola_nome_diretor'">
              <div>
                <b>{{ data.escola_nome_diretor }} </b>
              </div>
            </div>
            <div v-if="col.key == 'escola_todo'">
              <div v-for="n in data.escola_todo.escola_nome" :key="n">
                <b>{{ n.nome }} </b>
              </div>
              <div>
                <b>{{ data.escola_todo.escola_nome_diretor }} </b>
              </div>
            </div>
            <div v-if="col.key == 'segmentos'">
              <div v-if="data.segmentos.pre_escola == 1">
                <b style="color: blue"> Pre Escola </b>
              </div>
              <div v-if="data.segmentos.fundamental1 == 1">
                <b style="color: blue"> Fundamental I </b>
              </div>
              <div v-if="data.segmentos.fundamental2 == 1">
                <b style="color: blue"> Fundamental II </b>
              </div>
              <div v-if="data.segmentos.ern == 1">
                <b style="color: blue"> ERN </b>
              </div>
              <div v-if="data.segmentos.eja1 == 1">
                <b style="color: blue"> EJA ( 2 fase até a 5 fase) </b>
              </div>
              <div v-if="data.segmentos.eja2 == 1">
                <b style="color: blue"> EJA ( 6 fase até a 9 fase) </b>
              </div>
            </div>
            <div v-if="col.key == 'id'">
              <va-button-dropdown
                class="ml-2"
                color="#efefef"
                :rounded="false"
                v-if="situacaoAno"
              >
                <template #label>
                  <h5 style="font-size: 15px; margin-bottom: 0px">Ações</h5>
                </template>
                <div class="n_ger">
                  <va-list>
                    <va-list-item
                      v-if="acesso.editar"
                      @click="editarSegmento(data.id.id)"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <va-icon name="create" color="gray" />
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label> Edição </va-list-item-label>

                        <va-list-item-label caption>
                          Servidor
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>

                    <va-list-item
                      v-if="acesso.editar"
                      @click="acessoSistema(data.id.id)"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <va-icon name="create" color="gray" />
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label>
                          Acesso ao Sistema
                        </va-list-item-label>

                        <va-list-item-label caption>
                          Servidor
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>
                    <va-list-item
                      v-if="data.id.login_id == '0'"
                      @click="RemoverServidorSemLogin(data.id.id)"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <va-icon name="close" color="gray" />
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label>
                          Remover Servidor
                        </va-list-item-label>

                        <va-list-item-label caption>
                          Servidor
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>

                    <!--Professor-->
                    <div v-if="data.id.op.funcao_id == 5">
                      <div v-if="status[data.id.op.index] == true">
                        <!--horario-->
                        <va-list-item
                          @click="verTurnoProfessor(data.id.op)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="access_alarms" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label> Horários </va-list-item-label>

                            <va-list-item-label caption>
                              Professor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                        <!--Gerenciar Disciplinas-->
                        <va-list-item
                          @click="visualizarDisciplina(data.id.op)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="library_books" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Gerenciar Disciplinas
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Professor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                        <!--Visualizar Turma-->
                        <va-list-item
                          @click="professorSingle(data.id.id)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="group_work" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Visualizar Turmas
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Professor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                      </div>
                    </div>

                    <!--Diretor-->
                    <div v-if="data.id.diretor">
                      <div
                        v-if="
                          data.id.diretor.funcao_id == 3 ||
                            data.id.diretor.funcao_id == 5
                        "
                      >
                        <va-list-item
                          v-if="
                            !data.id.paraEditarDire &&
                              acesso.editar &&
                              (this.funcao == 1 ||
                                this.funcao == 2 ||
                                this.funcao == 7)
                          "
                          @click="registarDiretor(data.id.id)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="add_box" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Adicionar ao Ano Letivo
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Diretor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item
                          v-if="data.id.paraEditarDire && acesso.editar"
                          @click="editarDiret(data.id.diretor.id)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="create" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label> Editar </va-list-item-label>

                            <va-list-item-label caption>
                              Diretor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item
                          v-if="data.id.paraEditarDire && acesso.deletar"
                          @click="deletarDiret(data.id.diretor.id)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="group_work" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label> Deletar </va-list-item-label>

                            <va-list-item-label caption>
                              Diretor
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                      </div>
                    </div>

                    <!--Coordenador-->
                    <div v-if="data.id.coordenador">
                      <div v-if="data.id.coordenador.funcao_id == 4">
                        <va-list-item
                          v-if="!data.id.paraEditarCord && acesso.cadastrar"
                          @click="registarCoordenador(data.id.id)"
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="add_box" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Adicionar ao Ano Letivo
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item
                          v-if="data.id.paraEditarCord && acesso.editar"
                          @click="
                            editarCorde(data.id.coordenador.id_coordenador)
                          "
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="create" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label> Editar </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item
                          v-if="data.id.paraEditarCord && acesso.deletar"
                          @click="
                            deletarCorde(data.id.coordenador.id_coordenador)
                          "
                          style="cursor: pointer"
                        >
                          <va-list-item-section icon>
                            <va-icon name="group_work" color="gray" />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label> Deletar </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                      </div>
                    </div>

                    <va-list-item
                      @click="escolaridade(data.id.id)"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <va-icon name="create" color="gray" />
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label> Escolaridade </va-list-item-label>

                        <va-list-item-label caption>
                          Servidor
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>
                  </va-list>
                </div>
              </va-button-dropdown>
            </div>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag qdt="50" @changePagina="changePagina"></selectPorPag>
      </div>
    </div>

    <va-modal v-model="showModal" hide-default-actions overlay-opacity="0.2">
      <slot>
        <div>
          <img :src="foto_grande" style="width: 400px" />
        </div>
      </slot>
    </va-modal>

    <!--modal escolas e turnos disponiveis do professor-->
    <va-modal v-model="showMediumModal" hide-default-actions>
      <slot>
        <pm-Button
          label="Adicionar"
          size="small"
          class="p-button-success"
          style="float: right"
          @click="gerenciarHorario(this.info.id, 1)"
        >
        </pm-Button>
        <p>
          <b>{{ tituloModal }}</b>
        </p>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Escola</th>
              <th>Turno</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in textoModal" :key="n">
              <td>{{ n.escola.nome }}</td>
              <td>{{ n.turno }}</td>
              <td>
                <va-button size="small" @click="gerenciarHorario(n, 2)">
                  Editar
                </va-button>
                <va-button
                  size="small"
                  color="danger"
                  @click="deletarHorario(n.id)"
                >
                  Deletar
                </va-button>
              </td>
            </tr>
          </tbody>
        </table>
        <pm-Button
          label="voltar"
          class="p-button-secondary"
          style="float: right"
          @click="fecharModal"
        >
        </pm-Button>
      </slot>
    </va-modal>

    <!--modal Edição horario -->
    <va-modal v-model="showgerenciarHorarioModal" hide-default-actions>
      <slot>
        <form>
          <div class="row">
            <!--Adicionar-->
            <div class="col-12" v-if="tipo == 1">
              <p><b>Adicionar Turno do Professor</b></p>
              <div class="form-group col-md-12">
                <label>Selecione a Escola:</label>
                <select class="form-control" v-model="escolaSelecionada">
                  <option v-for="n in escolaALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Selecione o Turno:</label>
                <select class="form-control" v-model="turnoSelecionado">
                  <option v-for="n in horariosAll" :key="n" :value="n.nome">
                    {{ n.nome }}
                  </option>
                </select>
              </div>

              <div class="col-4" style="margin-top: 15px; padding: 0px">
                <pm-Button label="Adicionar" @click="cadastrarHorario()">
                </pm-Button>
              </div>
            </div>

            <!--Editar-->
            <div v-if="tipo == 2">
              <p><b>Editar Turno do Professor</b></p>
              <div class="form-group col-md-12">
                <label>Selecione a Escola:</label>
                <select class="form-control" v-model="escolaSelecionada">
                  <option v-for="n in escolaALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Selecione a Escola:</label>
                <select class="form-control" v-model="turnoSelecionado">
                  <option v-for="n in horariosAll" :key="n" :value="n.nome">
                    {{ n.nome }}
                  </option>
                </select>
              </div>

              <div class="col-md-3" style="margin-top: 15px">
                <va-button class="mr-2 mb-2" @click="editarHorario">
                  {{ $t("Editar") }}
                </va-button>
              </div>
            </div>
          </div>
        </form>
      </slot>
    </va-modal>

    <!--modal especialização do professor-->
    <va-modal v-model="showDisciplinaModal" hide-default-actions>
      <slot>
        <pm-Button
          label="Adicionar"
          class="p-button-success"
          style="float: right"
          @click="gereciadorEspecializacao(this.info.id, 1)"
        >
        </pm-Button>
        <p>
          <b>{{ tituloModal }}</b>
        </p>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Segmentos</th>
              <th>Disciplina</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in textoModal" :key="n">
              <td>{{ n.segmento.nome }}</td>
              <td>{{ n.disciplina.nome }}</td>
              <td>
                <va-button
                  size="small"
                  @click="
                    gereciadorEspecializacao(
                      n,
                      2
                    ) /*,editarDisciplina(3,'teste')*/
                  "
                >
                  Editar
                </va-button>
                <va-button
                  size="small"
                  color="danger"
                  @click="deletarDisciplina(n.id)"
                >
                  Apagar
                </va-button>
              </td>
            </tr>
          </tbody>
        </table>

        <pm-Button
          label="voltar"
          class="p-button-secondary"
          style="float: right"
          @click="fecharModal"
        >
        </pm-Button>
      </slot>
    </va-modal>

    <!--modal Edição especialização do professor -->
    <va-modal v-model="showgerenciarDisciplinaModal" hide-default-actions>
      <slot>
        <form>
          <div class="row">
            <!--adicionar-->
            <div v-if="this.tipo == 1">
              <p>
                <b>Adicionar Disciplina que o professor dará Aula aqui: </b>
              </p>
              <div class="form-group col-md-12">
                <label>Selecione o segmento:</label>
                <select
                  class="form-control"
                  v-model="segmentoSelecionado"
                  @change="selecionarEspecializacao(segmentoSelecionado)"
                >
                  <option v-for="n in segmentoALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
                <br />
              </div>
              <div
                class="form-group col-md-12"
                v-if="especializacaoTipoTemQueSelecionar"
              >
                <label>Selecione a especialização:</label>
                <select
                  class="form-control"
                  v-model="especializacaoSelecionada"
                  @change="disciplinaAtualizar(segmentoSelecionado, null)"
                >
                  <option
                    v-for="n in especializacaoTipo"
                    :key="n"
                    :value="n.id"
                  >
                    {{ n.nome }}
                  </option>
                </select>
                <br />
              </div>
              <div class="row" v-if="segmentoSelecionado == 4 || seg_tipo == 3">
                <slot>
                  <p>Selecione para quais fases:</p>
                  <div class="flex md12">
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="faseSelecionada"
                        label="II FASE - V FASE"
                      />
                      <va-radio
                        option="option2"
                        v-model="faseSelecionada"
                        label="VI FASE - IX FASE"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>
              <div class="row" v-if="seg_tipo == 1 || seg_tipo == 0">
                <slot
                  v-if="
                    seg_tipo == 0 ||
                      (seg_tipo == 1 && especializacaoSelecionada == 1)
                  "
                >
                  <p>Especialização do professor:</p>
                  <div class="flex md6">
                    <fieldset>
                      <va-radio
                        v-if="seg_tipo == 1"
                        option="option1"
                        v-model="radioSelectedOption"
                        label="Todas as disciplinas"
                      />
                      <va-radio
                        v-if="seg_tipo == 0"
                        option="option2"
                        v-model="radioSelectedOption"
                        label="Educação infantil"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>

              <div
                class="form-group col-md-12"
                v-if="
                  (segmentoSelecionado == 4 || seg_tipo == 3) &&
                    faseSelecionada == 'option1'
                "
              >
                <br />
                <h6>
                  <strong
                  >O professor será responsável por todas as
                    disciplina(Similar ao Fundamental I)</strong
                  >
                </h6>
              </div>
              <div
                class="form-group col-md-12"
                v-if="
                  (faseSelecionada == 'option2' && seg_tipo == 3) ||
                    seg_tipo == 2 ||
                    (seg_tipo == 1 && especializacaoSelecionada == 2)
                "
              >
                <label>Selecione a nova disciplina:</label>
                <select class="form-control" v-model="disciplinaSelecionada">
                  <option v-for="n in disciplinaALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
              </div>
              <div class="col-4" style="margin-top: 15px; padding: 0px">
                <pm-Button
                  label="Adicionar"
                  class="mr-2 mb-2"
                  v-if="
                    faseSelecionada == 'option1' ||
                      radioSelectedOption != '' ||
                      disciplinaSelecionada != ''
                  "
                  @click="cadastrarDisciplina()"
                >
                </pm-Button>
              </div>
            </div>

            <!--edicao-->
            <div v-if="this.tipo == 2">
              <p><b>Adicionar Disciplina que o Professor dará Aula</b></p>
              <div class="form-group col-md-12">
                <label>Selecione o segmento: </label>
                <select
                  class="form-control"
                  v-model="segmentoSelecionado"
                  @change="selecionarEspecializacao(segmentoSelecionado)"
                >
                  <!--   <option v-for="n in segmentoALL" :key="n" :value="n.id"  >{{n.nome}}</option>-->
                  <option v-for="n in segmentoALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
                <br />
              </div>

              <div
                class="form-group col-md-12"
                v-if="especializacaoTipoTemQueSelecionar"
              >
                <label>Selecione a especialização:</label>
                <select
                  class="form-control"
                  v-model="especializacaoSelecionada"
                  @change="disciplinaAtualizar(segmentoSelecionado, null)"
                >
                  <option
                    v-for="n in especializacaoTipo"
                    :key="n"
                    :value="n.id"
                  >
                    {{ n.nome }}
                  </option>
                </select>
                <br />
              </div>
              <div class="row" v-if="segmentoSelecionado == 4 || seg_tipo == 3">
                <slot>
                  <p>Selecione para quais fases:</p>
                  <div class="flex md12">
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="faseSelecionada"
                        label="II FASE - V FASE"
                      />
                      <va-radio
                        option="option2"
                        v-model="faseSelecionada"
                        label="VI FASE - IX FASE"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>
              <div class="row" v-if="seg_tipo == 1 || seg_tipo == 0">
                <slot
                  v-if="
                    seg_tipo == 0 ||
                      (seg_tipo == 1 && especializacaoSelecionada == 1)
                  "
                >
                  <p>Especialização do professor:</p>
                  <div class="flex md6">
                    <fieldset>
                      <va-radio
                        v-if="seg_tipo == 1"
                        option="option1"
                        v-model="radioSelectedOption"
                        label="Todas as disciplinas"
                      />
                      <va-radio
                        v-if="seg_tipo == 0"
                        option="option2"
                        v-model="radioSelectedOption"
                        label="Educação infantil"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>

              <div
                class="form-group col-md-12"
                v-if="
                  (segmentoSelecionado == 4 || seg_tipo == 3) &&
                    faseSelecionada == 'option1'
                "
              >
                <br />
                <h6>
                  <strong
                  >O professor será responsável por todas as
                    disciplina(Similar ao Fundamental I)</strong
                  >
                </h6>
              </div>

              <div
                class="form-group col-md-12"
                v-if="
                  (faseSelecionada == 'option2' && seg_tipo == 3) ||
                    seg_tipo == 2 ||
                    (seg_tipo == 1 && especializacaoSelecionada == 2)
                "
              >
                <label>Selecione a nova disciplina:</label>
                <select class="form-control" v-model="disciplinaSelecionada">
                  <option v-for="n in disciplinaALL" :key="n" :value="n.id">
                    {{ n.nome }}
                  </option>
                </select>
              </div>

              <div class="col-4" style="margin-top: 15px; padding: 0px">
                <pm-Button
                  label="Alterar"
                  class="mr-2 mb-2"
                  @click="editarDisciplina"
                >
                </pm-Button>
              </div>
            </div>
          </div>
        </form>
      </slot>
    </va-modal>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Servidores } from "@/class/servidores.js";
import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Turma } from "@/class/turma.js";
import { Coordenador } from "@/class/coordenador.js";
import { Diretor } from "@/class/diretor.js";
import { Verificar } from "@/class/verificar.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Configuracao } from "@/class/configuracao.js";
import { Escola } from "@/class/escolas.js";
import axios from "axios";

import { Funcao } from "@/class/funcao.js";

import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  props: {
    pg: {
      default: 1,
    },
    funcao: {
      default: 0,
    },
  },
  components: {
    selectPorPag,
  },
  data() {
    const columns = [
      { key: "codigo_INEP", label: "Codigo INEP" },
      { key: "nome", label: "Nome", sortable: true },
      { key: "opcoes", label: "O professor(a) está habilitado este ano?" },
      { key: "op", label: "Configuracão Anual " },
      { key: "id", label: "Ação" },
    ];

    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Servidores" }],
      items: [],
      faseSelecionada: "option1",
      radioSelectedOption: "option1",
      ehFundamentalI: 0,
      situacaoAno: 1,
      filter: "",
      showModal: false,
      foto_grande: null,
      showMediumModal: false,
      showDisciplinaModal: false,
      showgerenciarHorarioModal: false,
      showgerenciarDisciplinaModal: false,
      tipo: 1,
      ano: 0,
      info: [],

      servidores: 0,

      id_item: 0,
      segmentoSelecionado: "",
      disciplinaSelecionada: "",
      professorSelecionado: "",
      escolaSelecionada: 1,
      turnoSelecionado: "",

      disabledSelect: false,
      okParaEdicao: 0,
      status: [],
      disciplinaALL: [],
      situacaoAno: 1,
      showMediumModal: false,
      filter1: "",
      filter2: "",
      tituloModal: "",
      textoModal: "",
      servidoresEscolarALL: [],
      links: [],
      professorALL: [],
      turnosDeTrabalho: [],

      perPage1: 20,
      perPage2: 20,
      currentPage1: 1,
      currentPage2: 1,
      pagina_atual: 1,
      TheAno: 0,
      seg_tipo: -1,
      pesquisa: null,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      seriesALL: [],
      // serieSelecionada
      especializacaoTipoTemQueSelecionar: false,
      especializacaoSelecionada: "",
      especializacaoTipo: [
        { id: 1, nome: "Professor de Todas as disciplinas" },
        { id: 2, nome: "Selecionar Disciplina específica" },
      ],
    };
  },
  computed: {
    pages1() {
      return this.perPage1 && this.perPage1 !== "0"
        ? Math.ceil(this.servidoresEscolarALL.length / this.perPage1)
        : this.servidoresEscolarALL.length;
    },
    pages2() {
      return this.perPage2 && this.perPage2 !== "0"
        ? Math.ceil(this.professorALL.length / this.perPage2)
        : this.professorALL.length;
    },
  },
  methods: {
    changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg
      this.listarTudo()
    },
    //Ser for fundamental 1, listar as serias para pessoa
    async selecionarEspecializacao(segmento_id) {
      try {
        const data = await SegmentoEscolar.obtemUm(segmento_id);
        this.seg_tipo = data.data.tipo;
        //Se for do tipo 1, é fundamental 1
        if (data.data.tipo == 1) {
          this.especializacaoTipoTemQueSelecionar = true;
        } else {
          this.especializacaoTipoTemQueSelecionar = false;
          this.disciplinaAtualizar(segmento_id, null);
        }
      } catch (e) {
        console.log(e);
      }
    },
    fotoOpen(foto, sexo) {
      //quando a foto vier Null eu mando os Sexos 'F' ou 'M'
      //caso nao tiver null eu mando um 'A'

      if (sexo == "A") {
        this.foto_grande = this.base_url + "/storage/" + foto;
        this.showModal = true;
      }
    },

    async paginas(n) {
      if (n.url != null) {
        if (!n.active) {
          /*let res = await axios.get(n.url);
          this.turmasALL =[];
          this.links = res.data.links;*/

          const numero = n.url.split("?page=");
          this.pagina_atual = numero[1];

          this.listarTudo(n);
        }
      }
    },

    async pesquisar() {
      try {
        //pelo menos tres caracteres para pesquisar
        if (this.pesquisa != "" && this.pesquisa.length < 3) {
          return false;
        }
        this.professorALL = [];
        this.status = [];
        if (this.pesquisa != null && this.pesquisa != "") {
          const funcaoDoServidor = {
            0: 0,
            100: 100,
            1: 5,
            2: 4,
            3: 3,
            4: 2,
          };
          const nv = {
            nome: this.pesquisa,
            funcao: funcaoDoServidor[this.servidores],
          };
          // console.log(nv);
          const data = await Servidores.pesqServidorCompleto(nv);
          this.links = [];

          const columns = [
            { key: "foto", label: "FOTO DE PERFIL" },
            { key: "nome", label: "NOME", sortable: true },
            { key: "funcao", label: "FUNÇÃO", sortable: true },
            {
              key: "opcoes",
              label: "O PROFESSOR(A) ESTÁ HABILITADO ESTE ANO?",
            },

            { key: "escola_todo", label: "ESCOLA(S)" },
            { key: "segmentos", label: "SEGMENTOS COORDENADOR" },

            { key: "id", label: "Ação Servidor" },
          ];

          let novo;
          let funcao_dele = "",
            index = 0;
          // console.log(data.data);
          if (data.data) {
            this.professorALL = [];
            for (const el of data.data) {
              if (el.funcao == 5) {
                funcao_dele = "Professor";
              } else if (el.funcao == 4) {
                funcao_dele = "Coodernador";
              } else if (el.funcao == 3) {
                funcao_dele = "Diretor";
              } else if (el.funcao == undefined) {
                funcao_dele = "Sem função";
              } else {
                funcao_dele = "Gestor";
              }
              this.items = [];
              this.items = columns;

              novo = {
                id: {
                  id: el.id,
                  login_id: el.login_id,
                  foto: el.foto,
                  sexo: el.sexo,
                  op: {
                    index: index,
                    id: el.id,
                    ativo: el.habilitado_no_ano,
                    funcao_id: el.funcao,
                  },
                  info: {
                    id: el.id,
                  },
                  coordenador: {
                    id_coordenador: el.id,
                    funcao_id: el.funcao,
                  },
                  diretor: {
                    id: el.diretor.id,
                    servidor_id: el.diretor.servidor_id,
                    ano: el.diretor.ano,
                    escola: el.diretor.escola,
                    funcao_id: el.funcao,
                  },
                  paraEditarCord: el.escola_nome.length > 0,
                  paraEditarDire: el.diretor.escola_nome != undefined,
                },
                codigo_INEP: el.codigo_INEP,
                nome: el.nome_completo,
                funcao_id: el.funcao,
                funcao: funcao_dele,
                info: {
                  id: el.id,
                  nome: el.nome_completo,
                  funcao_id: el.funcao,
                },
                funcao_id: el.funcao,
                opcoes: {
                  index: index,
                  id: el.id,
                  funcao_id: el.funcao,
                  ativo: el.habilitado_no_ano,
                },
                escola_nome: el.escola_nome,
                op: {
                  index: index,
                  id: el.id,
                  ativo: el.habilitado_no_ano,
                  funcao_id: el.funcao,
                },
                coordenador: {
                  id_coordenador: el.id,
                  funcao_id: el.funcao,
                },
                diretor: {
                  id: el.diretor.id,
                  servidor_id: el.diretor.servidor_id,
                  ano: el.diretor.ano,
                  escola: el.diretor.escola,
                  funcao_id: el.funcao,
                },
                escola_nome_diretor: el.diretor.escola_nome,
                escola_todo: {
                  escola_nome_diretor: el.diretor.escola_nome,
                  escola_nome: el.escola_nome,
                },
                segmentos: {
                  pre_escola: el.pre_escola,
                  fundamental1: el.fundamental1,
                  fundamental2: el.fundamental2,
                  eja1: el.eja1,
                  eja2: el.eja2,
                  ern: el.ern,
                },
              };
              index++;
              el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
              this.status.push(el.habilitado_no_ano);
              this.professorALL.push(novo);
            }
          }
        } else {
          this.professorALL = [];
          this.listarTudo(this.pagina_atual);
        }
      } catch (e) {}
    },

    editarSegmento(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "servidor-basic-info-editar",
          params: { id: id, pg: this.pagina_atual },
        });
      } else {
        this.$router.push({
          name: "coordenador-servidor-basic-info-editar",
          params: { id: id, pg: this.pagina_atual },
        });
      }
    },
    acessoSistema(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "login-dados-pessoais-servidor-editar",
          params: { id: id, pg: this.pagina_atual },
        });
      } else {
        this.$router.push({
          name: "coordenador-login-dados-pessoais-servidor-editar",
          params: { id: id, pg: this.pagina_atual },
        });
      }
    },

    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "servidor-basic-info-cadastrar" });
      } else {
        this.$router.push({
          name: "coordenador-servidor-basic-info-cadastrar",
        });
      }
    },

    async mudarStatus(opcoes) {
      // desabilitar o professor
      this.disabledSelect = true;
      if (this.status[opcoes.index]) {
        //vericar antes de desabilitar o horario
        const verif = await Professor.ServidorVerificarHorarios(opcoes.id);

        if (verif.data.quantidade_de_horarios > 0) {
          const a =
            "Esse Professor possui Horarios em escolas! Só poderar desabilitar ele depois de remover esses horarios.";
          this.$vaToast.init({
            message: a,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 4000,
            fullWidth: false,
            color: "danger",
          });
          this.disabledSelect = false;
          this.status[opcoes.index] = true;
        } else {
          // ... continua
          const data = await Professor.obterProfessorIDServidor(opcoes.id);
          try {
            const data1 = await Professor.remover(data.data.id);
            this.disabledSelect = false;
          } catch (e) {
            this.disabledSelect = false;
            this.status[opcoes.index] = true;
            let a = [];
            a = e.response.data.error;
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 4000,
              fullWidth: false,
              color: "danger",
            });
            return false;
          }
        }
      } else {
        //habilitar o professor
        const novo = {
          ano: this.TheAno,
          servidor_id: opcoes.id,
        };
        // console.log(this.TheAno);
        try {
          const data = await Professor.cadastrar(novo);
          this.disabledSelect = false;
        } catch (e) {
          this.disabledSelect = false;
          this.status[opcoes.index] = false;
          let a = [];
          a = e.response.data.error;
          this.$vaToast.init({
            message: a,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 4000,
            fullWidth: false,
            color: "danger",
          });
          return false;
        }
      }
    },
    async listarTudo(n) {
      try {
        const routePg = pagina.routePg;
        this.professorALL = [];
        let data = [];
        if (n == 1) {
          const res = await Servidores.obtemTodosTipo(this.servidores, 1);
          data = res.data.data;

          this.links = res.data.links;
        } else {
          if (
            (typeof n === "object" || typeof n === "function") &&
            n !== null
          ) {
            const res = await axios.get(n.url+routePg);
            data = res.data.data;
            this.links = res.data.links;
          } else {
            const res = await Servidores.obtemTodosTipo(this.servidores, n);
            data = res.data.data;
            this.links = res.data.links;
          }
        }
        let funcao_dele = "",
          index = 0;
        this.status = [];
        this.professorALL = [];
        for (const el of data) {
          if (el.funcao == 5) {
            funcao_dele = "Professor";
          } else if (el.funcao == 4) {
            funcao_dele = "Coodernador";
          } else if (el.funcao == 3) {
            funcao_dele = "Diretor";
          } else {
            funcao_dele = "Gestor";
          }

          if (this.servidores == 1) {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              {
                key: "opcoes",
                label: "O PROFESSOR(A) ESTÁ HABILITADO ESTE ANO?",
              },
              { key: "escola_nome", label: "ESCOLA(S)" },

              //{ key: "op", label: "Configuracão Anual "},
              { key: "id", label: " " },
            ];
            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                foto: el.foto,
                sexo: el.sexo,
                op: {
                  index: index,
                  id: el.id,
                  ativo: el.habilitado_no_ano,
                  funcao_id: el.funcao,
                },
                info: {
                  id: el.id,
                },
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              habilitado_no_ano: el.habilitado_no_ano,
              funcao_id: el.funcao,
              opcoes: {
                index: index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id: el.funcao,
              },
              op: {
                index: index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id: el.funcao,
              },
              segmentos: {
                pre_escola: 0,
                fundamental1: 0,
                fundamental2: 0,
                eja1: 0,
                eja2: 0,
                ern: 0,
              },
              escola_nome: el.escola_nome,

            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);
            this.professorALL.push(novo);
          } else if (this.servidores == 2) {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              { key: "funcao", label: "FUNÇÃO", sortable: true },
              { key: "escola_nome", label: "ESCOLA(S)" },
              { key: "segmentos", label: "SEGMENTOS" },
              //{ key: "coordenador", label: "Ação Coordenador"},
              { key: "id", label: " " },
            ];

            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                foto: el.foto,
                sexo: el.sexo,
                op: {
                  index: index,
                  id: el.id,
                  ativo: el.habilitado_no_ano,
                  funcao_id: el.funcao,
                },
                info: {
                  id: el.id,
                },
                coordenador: {
                  id_coordenador: el.id,
                  funcao_id: el.funcao,
                },
                paraEditarCord: el.escola_nome.length > 0,
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              funcao_id: el.funcao,
              opcoes: {
                index: index,
                id: el.id,
                funcao_id: el.funcao,
                ativo: el.habilitado_no_ano,
              },
              escola_nome: el.escola_nome,
              op: {
                index: index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id: el.funcao,
              },
              coordenador: {
                id_coordenador: el.id,
                funcao_id: el.funcao,
              },
              segmentos: {
                pre_escola: el.pre_escola,
                fundamental1: el.fundamental1,
                fundamental2: el.fundamental2,
                eja1: el.eja1,
                eja2: el.eja2,
                ern: el.ern,
              },
            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);

            this.professorALL.push(novo);
          } else if (this.servidores == 3) {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              { key: "funcao", label: "FUNÇÃO", sortable: true },
              { key: "escola_nome_diretor", label: "ESCOLA(S)" },
              //{ key: "diretor", label: "Ação Diretor"},
              { key: "id", label: " " },
            ];

            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                foto: el.foto,
                sexo: el.sexo,
                op: {
                  index: index,
                  id: el.id,
                  ativo: el.habilitado_no_ano,
                  funcao_id: el.funcao,
                },
                info: {
                  id: el.id,
                },
                coordenador: {
                  id_coordenador: el.id_coordenador,
                  funcao_id: el.funcao,
                },
                diretor: {
                  id: el.diretor.id,
                  servidor_id: el.diretor.servidor_id,
                  ano: el.diretor.ano,
                  escola: el.diretor.escola,
                  funcao_id: el.funcao,
                },
                paraEditarDire: el.diretor.escola_nome != undefined,
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              funcao_id: el.funcao,
              opcoes: {
                index: index,
                id: el.id,
                funcao_id: el.funcao,
                ativo: el.habilitado_no_ano,
              },
              escola_nome_diretor: el.diretor.escola_nome,
              op: {
                index: index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id: el.funcao,
              },
              coordenador: {
                id_coordenador: el.id_coordenador,
                funcao_id: el.funcao,
              },
              diretor: {
                id: el.diretor.id,
                servidor_id: el.diretor.servidor_id,
                ano: el.diretor.ano,
                escola: el.diretor.escola,
                funcao_id: el.funcao,
              },
              segmentos: {
                pre_escola: el.pre_escola,
                fundamental1: el.fundamental1,
                fundamental2: el.fundamental2,
                eja1: el.eja1,
                eja2: el.eja2,
                ern: el.ern,
              },
            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);

            this.professorALL.push(novo);
          } else if (this.servidores == 4) {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              { key: "funcao", label: "FUNÇÃO", sortable: true },
              { key: "id", label: " " },
            ];

            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                foto: el.foto,
                sexo: el.sexo,
                op: {},
                info: {
                  id: el.id,
                },
                coordenador: {},
                diretor: {},
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              funcao_id: el.funcao,
            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);

            this.professorALL.push(novo);
          } else if (this.servidores == 100) {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              { key: "id", label: " " },
            ];

            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                login_id: el.login_id,
                foto: el.foto,
                sexo: el.sexo,
                op: {},
                info: {
                  id: el.id,
                },
                coordenador: {},
                diretor: {},
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              funcao_id: el.funcao,
            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);

            this.professorALL.push(novo);
          } else {
            const columns = [
              { key: "foto", label: "FOTO DE PERFIL" },
              { key: "nome", label: "NOME", sortable: true },
              { key: "funcao", label: "FUNÇÃO", sortable: true },
              {
                key: "opcoes",
                label: "O PROFESSOR(A) ESTÁ HABILITADO ESTE ANO?",
              },
              { key: "escola_todo", label: "ESCOLA(S)" },
              { key: "segmentos", label: "SEGMENTOS COORDENADOR" },
              { key: "id", label: " " },
            ];

            this.items = [];
            this.items = columns;
            const novo = {
              id: {
                id: el.id,
                foto: el.foto,
                sexo: el.sexo,
                op: {
                  index: index,
                  id: el.id,
                  ativo: el.habilitado_no_ano,
                  funcao_id: el.funcao,
                },
                info: {
                  id: el.id,
                },
                coordenador: {
                  id_coordenador: el.id,
                  funcao_id: el.funcao,
                },
                diretor: {
                  id: el.diretor.id,
                  servidor_id: el.diretor.servidor_id,
                  ano: el.diretor.ano,
                  escola: el.diretor.escola,
                  funcao_id: el.funcao,
                },
                paraEditarCord: el.escola_nome.length > 0,
                paraEditarDire: el.diretor.escola_nome != undefined,
              },
              nome: el.nome,
              sobrenome: el.sobrenome,
              deficiencia: el.deficiencia,
              qual_deficiencia: el.qual_deficiencia,
              funcao_id: el.funcao,
              funcao: funcao_dele,
              info: {
                id: el.id,
                nome: el.nome,
                funcao_id: el.funcao,
              },
              funcao_id: el.funcao,
              opcoes: {
                index: index,
                id: el.id,
                funcao_id: el.funcao,
                ativo: el.habilitado_no_ano,
              },
              escola_nome: el.escola_nome,
              op: {
                index: index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id: el.funcao,
              },
              coordenador: {
                id_coordenador: el.id,
                funcao_id: el.funcao,
              },
              diretor: {
                id: el.diretor.id,
                servidor_id: el.diretor.servidor_id,
                ano: el.diretor.ano,
                escola: el.diretor.escola,
                funcao_id: el.funcao,
              },
              escola_nome_diretor: el.diretor.escola_nome,
              escola_todo: {
                escola_nome_diretor: el.diretor.escola_nome,
                escola_nome: el.escola_nome,
              },
              segmentos: {
                pre_escola: el.pre_escola,
                fundamental1: el.fundamental1,
                fundamental2: el.fundamental2,
                eja1: el.eja1,
                eja2: el.eja2,
                ern: el.ern,
              },
            };
            index++;
            el.habilitado_no_ano = el.habilitado_no_ano ? true : false;
            this.status.push(el.habilitado_no_ano);

            this.professorALL.push(novo);
          }
        }
        //this.professorALL.reverse();
      } catch (e) {
        // console.log(e);
      }
    },

    async verTurnoProfessor(info) {
      this.tituloModal = "HORÁRIOS DE TRABALHO DO PROFESSOR";
      const data = await Professor.buscarTurnosProfessores(this.TheAno, info.id);

      this.tituloModal = "";
      this.textoModal = "";
      this.textoModal = data.data;
      this.info = [];
      this.info = info;

      this.showMediumModal = true;
    },

    async gerenciarHorario(item, tipo) {
      const dt = await Escola.obtemTodos();
      this.escolaALL = dt.data;
      const turnos = [
        { nome: "Matutino" },
        { nome: "Vespertino" },
        { nome: "Noturno" },
        { nome: "Diurno" },
      ];
      this.horariosAll = turnos;

      this.showMediumModal = false;
      this.showDisciplinaModal = false;
      if (tipo == 1) {
        this.tipo = 1;
        this.professorSelecionado = item;
      }
      if (tipo == 2) {
        this.tipo = 2;
        this.id_item = item.id;
        this.professorSelecionado = item.servidor_id;
        this.escolaSelecionada = item.escola_id;
        this.turnoSelecionado = item.turno;
      }
      this.showgerenciarHorarioModal = true;
    },

    async cadastrarHorario() {
      try {
        const novo = {
          ano: this.TheAno,
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          turno: this.turnoSelecionado,
        };

        this.professor = [];
        this.professor.push(novo);

        const verif = {
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          ano: this.TheAno,
        };

        const verturno = await Professor.verTurnosdoProfessor(verif);
        if (verturno.data.length > 0) {
          for (const el of verturno.data) {
            if (el.turno == this.turnoSelecionado) {
              alert("O professor ja possui Turno nessa escola!");
              return false;
            }
          }
        }

        const data = await Professor.addTurnoProfessor(this.professor[0]);

        this.showgerenciarHorarioModal = false;
        this.listarTudo(this.pagina_atual);

        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
      } catch (e) {}
    },

    async editarHorario() {
      try {
        const novo = {
          id: this.id_item,
          ano: this.TheAno,
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          turno: this.turnoSelecionado,
        };

        this.professor = [];
        this.professor.push(novo);

        const verif = {
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          ano: this.TheAno,
        };

        const verturno = await Professor.verTurnosdoProfessor(verif);
        if (verturno.data.length > 0) {
          for (const el of verturno.data) {
            if (el.turno == this.turnoSelecionado) {
              alert("O professor ja possui Turno nessa escola!");
              return false;
            }
          }
        }

        const data = await Professor.editarTurnosProfessor(novo);
        this.showgerenciarHorarioModal = false;
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        await this.listarTudo(this.pagina_atual);
      } catch (e) {}
    },

    async deletarHorario(id) {
      try {
        const data = await Professor.removerTurnoProfessor(id);
        this.$vaToast.init({
          message: "Excluído com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        this.showMediumModal = false;
        this.showDisciplinaModal = false;
        this.listarTudo(this.pagina_atual);
      } catch (e) {}
    },

    async visualizarDisciplina(info) {
      this.serieSelecionada = "";
      this.seriesALL = [];
      this.faseSelecionada = "";
      this.segmentoSelecionado = "";
      this.radioSelectedOption = "";
      this.disciplinaSelecionada = "";
      this.seg_tipo = -1;
      this.tituloModal = "DISCIPLINAS MINISTRADAS PELO PROFESSOR";
      const data = await Servidores.visualizarEspecializaoProf(info.id);

      this.tituloModal = "";
      this.textoModal = "";
      this.textoModal = data.data;
      this.info = [];
      this.info = info;

      this.showDisciplinaModal = true;
    },

    async gereciadorEspecializacao(item, tipo) {
      this.showMediumModal = false;
      this.showDisciplinaModal = false;
      const dt = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = dt.data;
      //tipo 1(cadastar)
      if (tipo == 1) {
        this.tipo = 1;
        this.especializacaoTipoTemQueSelecionar = false;
        this.especializacaoSelecionada = "";
        this.professorSelecionado = item;
        this.showgerenciarDisciplinaModal = true;
        return false;
      }
      //tipo 2(editar)
      if (tipo == 2) {
        if (item.segmento.tipo == 1) {
          this.especializacaoTipoTemQueSelecionar = true;
          this.seg_tipo = item.segmento.tipo;
        } else {
          this.especializacaoTipoTemQueSelecionar = false;
        }
        //eh do tipo fundamenta 1 com mais de um professor
        if (item.fund1_mais_de_um_professor) {
          this.especializacaoSelecionada = 2;
        }
        if (
          (item.segmento.tipo == 1 && !item.fund1_mais_de_um_professor) ||
          item.segmento.tipo == 0
        ) {
          if (item.disciplina_id == 1) {
            this.radioSelectedOption = "option1";
          } else {
            this.radioSelectedOption = "option2";
          }
        } else {
          //Verificar a fase do EJA. Se for entre II-V, será todas as matérias(disciplina_id = 1). Caso contrário, será apenas uma disciplina.
          if (item.disciplina_id == 1) {this.faseSelecionada = "option1";}
          else {this.faseSelecionada = "option2";}
        }
        this.tipo = 2;
        this.id_item = item.id;
        await this.disciplinaAtualizar(item.segmento_id, item);
        this.showgerenciarDisciplinaModal = true;
        return false;
      }
    },

    async disciplinaAtualizar(segmento, item) {
      if (this.tipo == 1) {
        this.faseSelecionada = "";
        this.radioSelectedOption = "";
        this.disciplinaSelecionada = "";
        this.seg_tipo = -1;
      }
      const dt1 = await SegmentoEscolar.obtemUm(segmento);
      this.seg_tipo = dt1.data.tipo;
      this.disciplinaALL = [];

      if (
        this.seg_tipo == 2 ||
        this.seg_tipo == 3 ||
        (this.seg_tipo == 1 && this.especializacaoSelecionada == 2)
      ) {
        const dt = await DisciplinaEscolar.obtemTodos();
        for (const el of dt.data) {
          if (el.id != 1 && el.id != 2) {
            const novo = {
              id: el.id,
              nome: el.nome,
              status: el.status,
            };
            this.disciplinaALL.push(novo);
          }
        }
        if (item != null) {
          this.disciplinaSelecionada = item.disciplina_id;
        }
      } else if (this.seg_tipo == 1 && this.especializacaoSelecionada == 1) {
        this.disciplinaSelecionada = 1;
        this.radioSelectedOption = "option1";
      } else if (this.seg_tipo == 0) {
        this.disciplinaSelecionada = 2;
        this.radioSelectedOption = "option2";
      }
      if (item != null) {
        this.professorSelecionado = item.servidor_id;
        this.segmentoSelecionado = item.segmento_id;
      }
    },

    async cadastrarDisciplina() {
      let fund1_mais_de_um_professor = 0;
      try {
        //se for do fundamental 1 e tiver apenas um professor, ou for da creche.
        if (
          (this.seg_tipo == 1 && this.especializacaoSelecionada == 1) ||
          this.seg_tipo == 0
        ) {
          if (this.radioSelectedOption == "option1") {
            this.disciplinaSelecionada = 1;
          } else {
            this.disciplinaSelecionada = 2;
          }
        } //se o eja selecionado for similar ao fundamental 1
        if (this.seg_tipo == 3 && this.faseSelecionada == "option1") {
          this.disciplinaSelecionada = 1;
        }
        //a turma possui mais de um professor
        if (this.seg_tipo == 1 && this.especializacaoSelecionada == 2)
          {fund1_mais_de_um_professor = 1;}

        const novo = {
          ano: this.TheAno,
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
          fund1_mais_de_um_professor: fund1_mais_de_um_professor,
          disciplina_id: this.disciplinaSelecionada,
        };
        this.professor = [];
        this.professor.push(novo);

        const verif = {
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
        };

        const verdiscplina =
          await Professor.buscarTodosProfessoresDeUmaDisciplina(verif);
        if (verdiscplina.data.length > 0) {
          for (const el of verdiscplina.data) {
            if (el.disciplina_id == this.disciplinaSelecionada) {
              alert("O professor ja possui essa Disciplina nessa Etapa!");
              return false;
            }
          }
        }

        const data = await Servidores.addEspecProfessor(this.professor[0]);
        this.showgerenciarDisciplinaModal = false;
        this.radioSelectedOption = "option1";
        this.listarTudo(this.pagina_atual);

        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
      } catch (e) {
        console.log(e);
      }
    },

    async editarDisciplina() {
      try {
        let fund1_mais_de_um_professor = 0;
        if (
          (this.seg_tipo == 1 && this.especializacaoSelecionada == 1) ||
          this.seg_tipo == 0
        ) {
          if (this.radioSelectedOption == "option1") {
            this.disciplinaSelecionada = 1;
          } else {
            this.disciplinaSelecionada = 2;
          }
        }
        //a turma possui mais de um professor
        if (this.seg_tipo == 1 && this.especializacaoSelecionada == 2)
          {fund1_mais_de_um_professor = 1;}

        const novo = {
          id: this.id_item,
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
          disciplina_id: this.disciplinaSelecionada,
          fund1_mais_de_um_professor: fund1_mais_de_um_professor,
        };

        this.professor = [];
        this.professor.push(novo);

        const verif = {
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
        };

        const verdiscplina =
          await Professor.buscarTodosProfessoresDeUmaDisciplina(verif);

        if (verdiscplina.data.length > 0) {
          for (const el of verdiscplina.data) {
            if (el.disciplina_id == this.disciplinaSelecionada) {
              alert("O professor ja possui essa Disciplina nessa Etapa!");
              return false;
            }
          }
        }

        const data = await Servidores.alterarEspecializacao(novo);
        this.$vaToast.init({
          message: "Alterado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
          color: "success",
        });
        this.showgerenciarDisciplinaModal = false;
        await this.listarTudo(this.pagina_atual);
        this.radioSelectedOption = "option1";
      } catch (e) {
        this.$vaToast.init({
          message: "Problema ao alterar!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
          color: "danger",
        });
      }
    },

    async deletarDisciplina(id) {
      try {
        if (
          window.confirm(
            "Você realmente deseja remover a disciplina do professor?"
          )
        ) {
          const data = await Servidores.deletarEspecializacao(id);
          this.$vaToast.init({
            message: "Excluído com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "success",
          });
          this.showMediumModal = false;
          this.showDisciplinaModal = false;
          this.listarTudo(this.pagina_atual);
        }
      } catch (e) {
        this.$vaToast.init({
          message: e.response.data.error,
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 4500,
          fullWidth: false,
          color: "danger",
        });
      }
    },

    fecharModal() {
      this.showMediumModal = 0;
      this.showDisciplinaModal = 0;
    },
    professorSingle(id) {
      this.$router.push({
        name: "professor",
        params: { id: id, pg: this.pagina_atual },
      });
    },

    async deletarCorde(id) {
      if (
        window.confirm(
          "Você realmente deseja remover está pessoa da coordenação?"
        )
      ) {
        try {
          const data1 = await Coordenador.obtemUmServidor(id);
          const data = await Coordenador.remover(data1.data.id);
          this.$vaToast.init({
            message: "Removido com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "success",
          });
          this.listarTudo(this.pagina_atual);
        } catch (e) {
          const a = e.response.data.erro;
          this.$vaToast.init({
            message: a,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "#e42222",
          });
        }
      }
    },
    editarCorde(id) {
      this.$router.push({
        name: "coordenadoresedit",
        params: { id: id, pg: this.pagina_atual },
      });
    },
    editarDiret(id) {
      this.$router.push({
        name: "diretoreditar",
        params: { id: id, pg: this.pagina_atual },
      });
    },
    async deletarDiret(id) {
      if (
        window.confirm("Você realmente deseja remover está pessoa da direção?")
      ) {
        try {
          const data = await Diretor.remover(id);
          this.$vaToast.init({
            message: "Removido com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "success",
          });
          this.listarTudo(this.pagina_atual);
        } catch (e) {
          const a = e.response.data.erro;
          this.$vaToast.init({
            message: a,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "#e42222",
          });
        }
      }
    },
    registarCoordenador(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "coordenadoresadd",
          params: { id: id, pg: this.pagina_atual },
        });
      } else {
        this.$router.push({
          name: "coordenadoresadd-coordenador",
          params: { id: id, pg: this.pagina_atual },
        });
      }
    },
    registarDiretor(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "diretoradd",
          params: { id: id, pg: this.pagina_atual },
        });
      } else {
        this.$router.push({
          name: "diretoradd-coordenador",
          params: { id: id, pg: this.pagina_atual },
        });
      }
    },

    async RemoverServidorSemLogin(id) {
      if (
        window.confirm("Você realmente deseja remover está pessoa do Sistema?")
      ) {
        try {
          const data = await Servidores.removerServidor(id);
          this.$vaToast.init({
            message: "Removido com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "success",
          });
          this.listarTudo(this.pagina_atual);
        } catch (e) {
          const a = e.response.data.erro;
          this.$vaToast.init({
            message: a,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "#e42222",
          });
        }
      }
    },

    escolaridade(id) {
      //console.log(id)
      this.$router.push({
        name: "escolaridade",
        params: { id: id, pg: this.pagina_atual },
      });
    },
  },
  async beforeMount() {
    if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
      this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
      this.pagina_atual = this.pg;
      await this.listarTudo(this.pg);
      const calendario = await Calendario.calendarioAtual();

      // this.TheAno = calendario.data.ano;
      this.TheAno = parseInt(sessionStorage.getItem("anoSelecionado"));
      // this.okParaEdicao = (anoSele == this.TheAno);
      const acesso = await Verificar.AcessoLocal(7);
      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;

      this.servidores = 0;
    } else {
      this.servidores = 1;
      if (this.$route.params.hasOwnProperty('tipo_servidor')) {
        this.servidores = this.$route.params.tipo_servidor;
      }
      const acesso = await Verificar.AcessoLocal(9);
      this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
      this.pagina_atual = this.pg;
      await this.listarTudo(this.pg);
      const calendario = await Calendario.calendarioAtual();
      this.TheAno = calendario.data[0].ano;
      const anoSele = parseInt(sessionStorage.getItem("anoSelecionado"));
      this.okParaEdicao = anoSele == this.TheAno;

      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;
    }
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.va-list-item:hover {
  background-color: #f5f5f5;
}
.va-list-item__inner {
  padding-right: 30px;
}
.va-dropdown__content {
  padding: 0;
}
.form-control {
  background-color: #fff !important;
}
</style>
